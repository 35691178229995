<template>
  <div class="mainform">
    <div class="infomation" v-if="haveInfo">您好，xxxx公司信息审批未通过</div>
    <div class="mainHeader" style="color:#00aaff">
      {{formName}}
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <div class="header">基本信息</div>
      <div class="main">
        <div class="col col4" v-if="!FormAttr.code.hidden">
          <div class="title">
            <span v-if="!FormAttr.code.readonly" class="priority">*</span>供应商编号
          </div>
          <div class="input" :class="{ inputReadonly: FormAttr.code.readonly }">
            <input :readonly="FormAttr.code.readonly" v-model="formData.erpCode" :required="FormAttr.code.required" placeholder="系统自动生成">
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.name.hidden">
          <div class="title"><span v-if="!FormAttr.name.readonly" class="priority">*</span>供应商名称</div>
          <div class="input" :class="{ inputReadonly: FormAttr.name.readonly }">
            <input :readonly="FormAttr.name.readonly"  v-model="formData.name" :required="FormAttr.name.required" placeholder="请输入供应商名称">
          </div>
        </div>
        <div class="col col4"  v-if="!FormAttr.openingBank.hidden">
          <div class="title"><span v-if="!FormAttr.openingBank.readonly" class="priority">*</span>开户行名称</div>
          <div class="input" :class="{ inputReadonly: FormAttr.openingBank.readonly }">
            <input :readonly="FormAttr.openingBank.readonly"  v-model="formData.openingBank" :required="FormAttr.openingBank.required" placeholder="请输入开户行名称">
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.creditCode.hidden">
          <div class="title"><span  v-if="!FormAttr.creditCode.readonly" class="priority">*</span>统一信用代码</div>
          <div class="input" :class="{ inputReadonly: FormAttr.creditCode.readonly }">
            <input :readonly="FormAttr.creditCode.readonly"  v-model="formData.creditCode" @blur="verifySocialCode(formData.creditCode,formData,'creditCode')" :required="FormAttr.creditCode.required" placeholder="请输入统一信用代码">
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.bankCode.hidden">
          <div class="title"><span v-if="!FormAttr.bankCode.readonly" class="priority">*</span>银行账号</div>
          <div class="input" :class="{ inputReadonly: FormAttr.bankCode.readonly }">
            <input :readonly="FormAttr.bankCode.readonly" v-model="formData.bankCode" @blur="verifyBankCard(formData.bankCode,formData,'bankCode')" :required="FormAttr.bankCode.required" placeholder="请输入银行账号">
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.businessAddress.hidden">
          <div class="title"><span v-if="!FormAttr.businessAddress.readonly" class="priority">*</span>公司营业地址</div>
          <div class="input" :class="{ inputReadonly: FormAttr.businessAddress.readonly }">
            <input :readonly="FormAttr.businessAddress.readonly" v-model="formData.businessAddress" :required="FormAttr.businessAddress.required" placeholder="公司营业地址">
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.businessScope.hidden">
          <div class="title"><span v-if="!FormAttr.businessScope.readonly" class="priority">*</span>经营范围</div>
          <div class="input" :class="{ inputReadonly: FormAttr.businessScope.readonly }">
            <el-select filterable multiple :disabled="FormAttr.businessScope.readonly" v-model="formData.businessScopeList" :required="FormAttr.businessScope.required" placeholder="请选择">
              <el-option
                  v-for="item in businessScopeData"
                  :key="item.value"
                  :label="item.companyDesc"
                  :value="item.businessScope">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.supplyMaterialType.hidden">
          <div class="title"><span  v-if="!FormAttr.supplyMaterialType.readonly" class="priority">*</span>供应物料类别</div>
          <div class="input" :class="{ inputReadonly: FormAttr.supplyMaterialType.readonly }">
            <el-select filterable multiple :disabled="FormAttr.supplyMaterialType.readonly" v-model="formData.supplyMaterialTypeList" :required="FormAttr.supplyMaterialType.required" placeholder="请选择">
              <el-option
                  v-for="item in supplyMaterialTypeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.type.hidden">
          <div class="title"><span v-if="!FormAttr.type.readonly" class="priority">*</span>供应商级别</div>
          <div class="input input-radio" v-if="!FormAttr.type.readonly">
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="1">一次</span>
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="2">一般</span>
            <span class="radio"><input :disabled="FormAttr.type.readonly" :required="FormAttr.type.required" v-model="formData.type" name="type" type="radio" value="3">合格</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.type.readonly">
            <input :readonly="true" :value='formData.type=="1"?"一次":formData.type=="2"?"一般":formData.type=="3"?"合格":formData.type'>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.vmi.hidden">
          <div class="title"><span v-if="!FormAttr.vmi.readonly" class="priority">*</span>VMI</div>
          <div class="input input-radio" v-if="!FormAttr.vmi.readonly">
            <span class="radio"><input :disabled="FormAttr.vmi.readonly" :required="FormAttr.vmi.required" v-model="formData.vmi" name="vmi" type="radio" value=1>是</span>
            <span class="radio"><input :disabled="FormAttr.vmi.readonly" :required="FormAttr.vmi.required" v-model="formData.vmi" name="vmi" type="radio" value=2>否</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.vmi.readonly">
            <input :readonly="true" :value='formData.vmi=="1"?"是":formData.vmi=="2"?"否":""'>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.status.hidden">
          <div class="title"><span v-if="!FormAttr.status.readonly" class="priority">*</span>状态</div>
          <div class="input input-radio" v-if="!FormAttr.status.readonly">
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=3>启用</span>
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=1>淘汰</span>
            <span class="radio"><input :disabled="FormAttr.status.readonly" :required="FormAttr.status.required" v-model="formData.status" name="status" type="radio" value=2>冻结</span>
          </div>
          <div class="input inputReadonly" v-if="FormAttr.status.readonly">
            <input :readonly="true" :value='formData.status=="1"?"淘汰":formData.status=="2"?"冻结":formData.status=="3"?"启用":formData.status'>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.buyerName.hidden">
          <div class="title"><span  v-if="!FormAttr.buyerName.readonly" class="priority">*</span>采购员</div>
          <div class="input" :class="{ inputReadonly: FormAttr.buyerName.readonly }">
            <el-select filterable multiple value-key="buyerCode" :disabled="FormAttr.buyerName.readonly" v-model="formData.buyerDtoList" :required="FormAttr.buyerName.required" placeholder="请选择采购员">
              <el-option
                  v-for="item in participantOptions"
                  :key="item.username"
                  :label="item.name"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col col4" v-if="!FormAttr.buyerName.hidden&&havebjfzrsp">
          <div class="title"><span class="priority">*</span>部件负责人</div>
          <div class="input">
            <el-select filterable v-model="formData.bjfzrsp" :required="true" placeholder="请选择部件负责人">
              <el-option
                  v-for="item in bjfzrsplist"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col col4"  v-if="!FormAttr.statement.hidden">
          <div class="title"><span v-if="!FormAttr.statement.readonly" class="priority">*</span>对账单</div>
          <div class="input input-radio" :class="{ inputReadonly: FormAttr.statement.readonly }">
            <span class="radio"><input :disabled="FormAttr.statement.readonly" :required="FormAttr.statement.required" v-model="formData.isAutomatic" name="statement" type="radio" value="0">系统创建</span>
            <span class="radio"><input :disabled="FormAttr.statement.readonly" :required="FormAttr.statement.required" v-model="formData.isAutomatic" name="statement" type="radio" value="1">手动创建</span>
          </div>
        </div>
<!-- 付款条件 -->
        <div class="col col4" v-if="!FormAttr.payClauseCode.hidden">
          <div class="title"><span v-if="!FormAttr.payClauseCode.readonly" class="priority">*</span>付款条件</div>
          <div class="input" :class="{ inputReadonly: FormAttr.payClauseCode.readonly }">
            <el-select filterable :disabled="FormAttr.payClauseCode.readonly" v-model="formData.payClauseCode" :required="FormAttr.payClauseCode.required" placeholder="请选择">
              <el-option
                  v-for="item in payClauseCodeList"
                  :key="item.ZTERM"
                  :label="item.TEXT1"
                  :value="item.ZTERM">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="col col4" v-if="!FormAttr.applicationReason.hidden">
          <div class="title"><span v-if="!FormAttr.applicationReason.readonly" class="priority">*</span>申请原因</div>
          <div class="input" :class="{ inputReadonly: FormAttr.applicationReason.readonly }">
            <input :readonly="FormAttr.applicationReason.readonly" v-model="formData.applicationReason" :required="FormAttr.applicationReason.required" placeholder="申请原因">
          </div>
        </div>

        <div class="col col4"  v-if="(formData.erpCode??'')!==''&&(!FormAttr.upgradeLevel.hidden||(formData.upgradeLevel!==null&&formData.upgradeLevel!=''))">
          <div class="title"><span v-if="!FormAttr.upgradeLevel.readonly" class="priority">*</span>{{$route.query.controlLevel === 'up'?'供应商升级':$route.query.controlLevel === 'down'?'供应商降级':'修改后级别'}}</div>
          <div class="input input-radio" :class="{ inputReadonly: (FormAttr.upgradeLevel.readonly && $route.query.controlLevel) }">
            <span v-for="item in upgradeLevelList" :key="item.value" class="radio">
              <input :disabled="(FormAttr.upgradeLevel.readonly || !$route.query.controlLevel)" :required="FormAttr.upgradeLevel.required" v-model="formData.upgradeLevel" name="upgradeLevel" type="radio" :value="item.value">
              {{item.label}}
            </span>
          </div>
        </div>
        <div class="col col4"  v-if="!FormAttr.eliminateOpinion.hidden||((formData.eliminateOpinion??'').trim()!='')">
          <div class="title"><span v-if="!FormAttr.eliminateOpinion.readonly" class="priority">*</span>降级原因</div>
          <div class="input input-radio" :class="{ inputReadonly: FormAttr.eliminateOpinion.readonly }">
            <el-input :disabled="FormAttr.eliminateOpinion.readonly || FormAttr.eliminateOpinion.hidden" type="textarea" v-model="formData.eliminateOpinion "></el-input>
          </div>
        </div>
      </div>
      <div class="header">地址</div>
      <div class="otherTable" v-show="!FormAttr.supplierAddressList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.supplierAddressList" >
          <div class="card">
            <div class="col col4" v-show="!FormAttr.supplierAddressList.address_property.hidden">
              <div class="title"><span v-if="!FormAttr.supplierAddressList.address_property.readonly" class="priority">*</span>地址属性</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.address_property.readonly }">
                <el-select filterable :disabled="FormAttr.supplierAddressList.address_property.readonly"
                 :required="FormAttr.supplierAddressList.address_property.required"
                 v-model="formData.supplierAddressList[key].addressProperty" placeholder="请选择">
                  <el-option label="返修地址" value=1></el-option>
                  <el-option label="仓库地址" value=2></el-option>
                  <el-option label="其他地址" value=3></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col4" v-if="FormAttr.supplierAddressList.addressName.readonly">
              <div class="title">地址名称</div>
              <div class="input inputReadonly">
                <input :readonly="true" :value="formData.supplierAddressList[key].addressName" >
              </div>
            </div>
            <div class="col col8 col_des"  v-show="!FormAttr.supplierAddressList.addressName.hidden">
              <div class="title"><span v-if="!FormAttr.supplierAddressList.addressName.readonly" class="priority">*</span>地址名称</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.addressName.readonly }">
                <input :readonly="FormAttr.supplierAddressList.addressName.readonly"
                :required="FormAttr.supplierAddressList.addressName.required"
                v-model="formData.supplierAddressList[key].addressName" placeholder="地址名称">
              </div>
              <div class="label" v-if="!FormAttr.supplierAddressList.addressName.readonly">地址名称用于区分同类型地址，例如：北京返修地址、天津返修地址</div>
            </div>
            <div class="col col8" v-show="!FormAttr.supplierAddressList.address.hidden">
              <div class="title">
                <span v-if="!FormAttr.supplierAddressList.address.readonly" class="priority">*</span>
                <span v-if="!FormAttr.supplierAddressList.address.readonly">选择地址</span>
                <span v-if="FormAttr.supplierAddressList.address.readonly">地址详情</span>
              </div>
              <el-select :disabled="FormAttr.supplierAddressList.address.readonly"
                 v-model="formData.supplierAddressList[key].country" placeholder="请选择">
                  <el-option label="中国" value='中国'></el-option>
                </el-select>
              <el-cascader
                style="width:300px;margin-left:20px"
                :disabled="FormAttr.supplierAddressList.address.readonly"
                v-model="formData.supplierAddressList[key].areaData" :options="areaDataList" :props="{ expandTrigger: 'hover' }"  @change="handleChangeCity(formData.supplierAddressList[key])"
              />
              <!-- <div class="input inputReadonly" v-if="FormAttr.supplierAddressList.address.readonly">

              </div> -->
              <!-- <area-selector :readonly="FormAttr.supplierAddressList.address.readonly" v-if="!FormAttr.supplierAddressList.address.readonly"
                  :required="FormAttr.supplierAddressList.address.required"
                  :countrydData = "formData.supplierAddressList[key].country"
                  :provincedData = "formData.supplierAddressList[key].province"
                  :citydData = "formData.supplierAddressList[key].city"
                  :districtdData = "formData.supplierAddressList[key].area"
                 @changAction="changAction" :addrIndex="key"></area-selector>
                 <div class="input inputReadonly" v-if="FormAttr.supplierAddressList.address.readonly">
                    <input :readonly="true" :value='formData.supplierAddressList[key].country+
                    formData.supplierAddressList[key].province+
                    formData.supplierAddressList[key].city+
                    formData.supplierAddressList[key].area+
                    formData.supplierAddressList[key].detailedAddress'>
                </div> -->
            </div>
            <div class="col col8" v-show="!FormAttr.supplierAddressList.detailedAddress.hidden">
              <div class="title"></div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierAddressList.detailedAddress.readonly }">
                <input :readonly="FormAttr.supplierAddressList.detailedAddress.readonly"
                :required="FormAttr.supplierAddressList.detailedAddress.required"
                v-model="formData.supplierAddressList[key].detailedAddress" placeholder="请输入详细地址">
              </div>
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.supplierAddressList.readonly">
            <div v-if="formData.supplierAddressList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.supplierAddressList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.supplierAddressList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.supplierAddressList,supplierAddressList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header">联系人</div>
      <div class="otherTable" v-show="!FormAttr.supplierContactList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.supplierContactList">
          <div class="card">
            <div class="col col4" v-show="!FormAttr.supplierContactList.name.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.name.readonly" class="priority">*</span>联系人姓名</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.name.readonly }">
                <input :readonly="FormAttr.supplierContactList.name.readonly"
                :required="FormAttr.supplierContactList.name.required"
                 v-model="formData.supplierContactList[key].name" placeholder="联系人姓名">
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.phone.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.phone.readonly" class="priority">*</span>电话</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.phone.readonly }">
                <input :readonly="FormAttr.supplierContactList.phone.readonly"
                :required="FormAttr.supplierContactList.phone.required"
                 @blur="verifyPhone(formData.supplierContactList[key].phone,formData.supplierContactList[key],'phone')"
                 v-model="formData.supplierContactList[key].phone" placeholder="电话">
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.deptCode.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.deptCode.readonly" class="priority">*</span>部门</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.deptCode.readonly }">
                 <el-select :disabled="FormAttr.supplierContactList.deptCode.readonly"
                    :required="FormAttr.supplierContactList.deptCode.required"
                    v-model="formData.supplierContactList[key].deptCode"
                    placeholder="请选择部门">
                    <el-option label="销售部" value="销售部"></el-option>
                    <el-option label="采购部" value="采购部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="品质部" value="品质部"></el-option>
                    <el-option label="产品部" value="产品部"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col4" v-show="!FormAttr.supplierContactList.email.hidden">
              <div class="title"><span v-if="!FormAttr.supplierContactList.email.readonly" class="priority">*</span>邮箱</div>
              <div class="input" :class="{ inputReadonly: FormAttr.supplierContactList.email.readonly }">
                <input :readonly="FormAttr.supplierContactList.email.readonly"
                :required="FormAttr.supplierContactList.email.required"
                 v-model="formData.supplierContactList[key].mail"
                 @blur="verifyEmail(formData.supplierContactList[key].mail,formData.supplierContactList[key],'mail')"
                 placeholder="邮箱">
              </div>
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.supplierContactList.readonly">
            <div v-if="formData.supplierContactList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.supplierContactList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.supplierContactList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.supplierContactList,supplierContactList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header">认证文件</div>
      <div class="otherTable" v-show="!FormAttr.srmFileList.hidden">
        <div class="mainBody" :key="key" v-for="(item,key) in formData.fileVoList">
          <div class="card">
            <div class="col col8 col_des" v-show="!FormAttr.srmFileList.name.hidden">
              <div class="title"><span v-if="!FormAttr.srmFileList.name.readonly" class="priority">*</span>文件类型</div>
              <div class="input" :class="{ inputReadonly: FormAttr.srmFileList.name.readonly }">
                <el-select filterable :disabled="FormAttr.srmFileList.name.readonly"
                 :required="FormAttr.srmFileList.name.required"
                 v-model="formData.fileVoList[key].name" placeholder="请选择">
                  <el-option label="营业执照" value="营业执照"></el-option>
                  <el-option label="品质认证文件" value="品质认证文件"></el-option>
                  <el-option label="其他文件" value="其他文件"></el-option>
                </el-select>
              </div>
            </div>
            <div class="col col8" v-show="!FormAttr.srmFileList.srmFileList.hidden">
              <div class="title"><span v-if="!FormAttr.srmFileList.srmFileList.readonly" class="priority">*</span>文件</div>
              <el-upload
                  :disabled="FormAttr.srmFileList.srmFileList.readonly"
                  :required="FormAttr.srmFileList.srmFileList.required"
                  :class="{ uploadReadonly: FormAttr.srmFileList.srmFileList.readonly }"
                  @click="clickKey(key)"
                  :action="actionURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :headers="tokenHeader"
                  :on-success="handleSuccess"
                  :before-upload="beforeUpload" 
                  multiple
                  :limit="10"
                  :on-exceed="handleExceed"
                  :file-list="formData.fileVoList[key].srmFileList"
                >
                  <el-button v-if="!FormAttr.srmFileList.srmFileList.readonly" size="small" type="primary" plain>点击上传</el-button>
                  <template #tip>
                    <span v-if="!FormAttr.srmFileList.srmFileList.readonly" class="el-upload__tip" style="margin-left:10px"></span>
                  </template>
                </el-upload>
               
            </div>
          </div>
          <div class="menu" v-if="!FormAttr.srmFileList.readonly">
            <div v-if="formData.fileVoList.length>1">
              <el-button type="primary" plain icon="el-icon-minus" @click="dellist(formData.fileVoList,key)" circle size="mini"></el-button>
            </div>
            <div v-if="formData.fileVoList.length-1==key">
              <el-button type="primary" plain icon="el-icon-plus" @click="addlist(formData.fileVoList,srmFileList)" circle size="mini"></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header">资料调查表</div>
      <div class="otherTable" v-show="!FormAttr.resourceFileList.hidden">
        <div class="muban"><span @click="handlePreview({id: '765707918494261248',name: '调查表（贸易商）模板.xlsx'})">调查表（贸易商）模板</span>
          <span @click="handlePreview({id: '765707951146917888',name: '调查表（制造商）模板.xlsx'})">调查表（制造商）模板</span>
          <span @click="handlePreview({id: '765789532721111040',name: '分包商调查表.doc'})">分包商调查表</span>
        </div>
        <div class="mainBody">
          <div class="card">
            <div class="col col8" v-show="!FormAttr.resourceFileList.hidden">
              <div class="title"><span v-if="!FormAttr.resourceFileList.readonly" class="priority">*</span>文件</div>
              <el-upload
                  :disabled="FormAttr.resourceFileList.readonly"
                  :required="FormAttr.resourceFileList.required"
                  :class="{ uploadReadonly: FormAttr.resourceFileList.readonly }"
                  @click="clickKey(key)"
                  :action="actionURL"
                  :on-preview="handlePreview"
                  :on-remove="handleRemoveResource"
                  :before-remove="beforeRemove"
                  :headers="tokenHeader"
                  :on-success="handleSuccessResource"
                  :before-upload="beforeUpload" 
                  multiple
                  :limit="10"
                  :on-exceed="handleExceed"
                  :file-list="formData.resourceFileList"
                >
                  <el-button v-if="!FormAttr.resourceFileList.readonly" size="small" type="primary" plain>点击上传</el-button>
                  <template #tip>
                    <span v-if="!FormAttr.resourceFileList.readonly" class="el-upload__tip" style="margin-left:10px"></span>
                  </template>
                </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div v-if="supplierScoreList&&(supplierScoreList.length > 0 || greadtot>=60)" class="header">打分<span v-if="greadtot>=60" style="float: right;">总分：{{greadtot}}</span></div>
      <div v-if="supplierScoreList&&supplierScoreList.length > 0" class="otherTable">
          <div class="mainBody">
            <div class="card">
              <div  v-for="(item, index) in supplierScoreList"
                  :key="item.mainIndicator" class="col col8 col_des">
                  <div class="title" style="width: 25%;">
                    <span v-if="canGrade" class="priority">*</span>
                    {{ item.mainIndicator }}（最高分：{{item.tallestScore}}）
                  </div>
                  <div class="input" :class="{ inputReadonly: !canGrade }" style="width: 30%;">
                    <input
                      v-model="item.score"
                      :placeholder="item.mainIndicator"
                      autocomplete="off"
                      :readonly="!canGrade"
                      @input="(e)=>checkNum(e, item.tallestScore, index)"
                    >
                  </div>
                  <div class="labelappend" style="width: 45%;">{{item.nameStandard}}</div>
              </div>
              <div class="col col8 col_des">
                <div class="title" style="width: 25%;">
                    总分
                  </div>
                  <div class="input" style="width: 30%;line-height: 30px;text-indent: 7px;">
                    <span v-if="canGrade||greadtot<=0">{{graedte()}}</span>
                    <span v-else>{{greadtot}}</span>
                  </div>
                  <div class="labelappend" style="width: 45%;"></div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="mainform">
      <div class="form">
        <approval-mind></approval-mind>
      </div>
    </div>
    <div class="action">
      <el-button v-if="canChange" @click="change" class="change" type="primary" size="medium">修改</el-button>
      <el-button v-if="canSave" @click="save" class="save" type="primary" size="medium">保存</el-button>
      <el-button v-if="canSubmit" @click="submit" class="submit" type="primary" size="medium">提交</el-button>
      <el-button v-if="canGrade" @click="grade" class="submit" type="primary" size="medium">提交</el-button>
      <el-button v-if="canReject" @click="reject" class="reject" type="primary" size="medium">驳回</el-button>
      <el-button v-if="canUp" @click="upGrade" class="upGrade" type="primary" size="medium">升级</el-button>
      <el-button v-if="canDown" @click="downGrade" class="downGrade" type="primary" size="medium">降级</el-button>
      <el-button v-if="canPayClauseChange" @click="payClauseChangeACt" class="downGrade" type="primary" size="medium">提交</el-button>
      <el-button v-if="(showCallSap)" @click="callSap" class="reject" type="primary" size="medium">调用SAP</el-button>
    </div>
  </div>
</template>

<script>
import { request, exportForm, requestForm, deepClone } from '@/assets/js/http.js'
import { baseHost, tokenHeader } from '@/assets/js/PublicData.js'
import { MIME } from '@/assets/js/mime.js'
import { ElMessage } from 'element-plus'
import {
  SupplierRegistrFormData,
  supplierAddressList,
  supplierContactList,
  srmFileList,
  SupplierRegistrFormAttr,
  SupplierRegistrFormShow,
  SupplierRegistrFormEdit,
  SupplierRegistrFormAttrCompany,
  supplyMaterialTypeData
} from './js/SupplierConfig.js'
import { FlowCtlData, getApprPsn } from '@/assets/js/FlowManage.js'
import AreaSelector from '@/components/plug/AreaSelector'
import ApprovalMind from '@/components/ApprovalMind'

export default {
  name: 'SupplierRegistration',
  // eslint-disable-next-line vue/no-unused-components
  components: { AreaSelector, ApprovalMind },
  data () {
    FlowCtlData.flowid = 'SupplierRegistration'
    FlowCtlData.docid = ''
    return {
      formData: deepClone(SupplierRegistrFormData),
      tokenHeader: tokenHeader,
      participantOptions: [],
      actionURL: baseHost.mspUrl + '/api/file/parastor/upload/file?bucket=srm',
      businessScopeData: [],
      supplyMaterialTypeData: supplyMaterialTypeData,
      supplierAddressList: supplierAddressList,
      supplierContactList: supplierContactList,
      srmFileList: srmFileList,
      FormAttr: SupplierRegistrFormShow,
      formName: '新建供应商',
      payClauseCodeList: [],
      uploadKey: 0,
      mustApproval: false,
      canChange: false,
      initbjfzrsphw: false,
      canSave: false,
      havebjfzrsp: false,
      canSubmit: false,
      canReject: false,
      canGrade: false,
      showCallSap: false,
      haveInfo: false,
      bjfzrsplist: [],
      tempBuyerName: {},
      greadtot: 0,
      supplierScoreList: [], // 打分列表
      canUp: false, // 展示升级按钮
      canDown: false, // 展示降级按钮
      canPayClauseChange: false,
      upgradeLevelList: [
        { value: '3', label: '合格' },
        { value: '2', label: '一般' },
        { value: '1', label: '一次' },
        { value: '0', label: '淘汰' }
      ],
      areaDataList: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.initialize()
      }
    },
    'formData.type': function (val) {
      var id = this.$route.query.id
      if (this.$store.state.loginInfo.userType === 0) {
        if (id) {
          if (this.initbjfzrsphw) {
            // eslint-disable-next-line eqeqeq
            if (val == 3) {
              this.havebjfzrsp = true
            } else {
              this.havebjfzrsp = false
            }
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (val == 3) {
            this.havebjfzrsp = true
          } else {
            this.havebjfzrsp = false
          }
        }
      }
    }
  },
  created () {
    if (window.location.host === '10.0.102.43') {
      this.actionURL = baseHost.mspTestUrl + '/api/file/parastor/upload/file?bucket=srm'
    }
    window.SupplierRegistrationApp = this
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeData = response
    })
    const BASE_URL = window.location.href.indexOf('10.0.102.43') > 0 ? 'https://msptest.sugon.com' : 'https://msp.sugon.com'
    request(BASE_URL + '/api/address/district/findOptionAll', 'post', {}).then((response) => {
      if (response.code === '200') {
        this.areaDataList = response.data
      }
    })
    requestForm('/api/supplier/basicData/getPayList', 'post').then((response) => {
      if (response.code === '200') {
        this.payClauseCodeList = response.data
      }
    })
    requestForm('/api/system/role/getUserByRoleId', 'post', { roleId: '540776582185422848' }).then((response) => {
      if (response.code === '200') {
        this.participantOptions = response.data
        for (const item of this.participantOptions) {
          item.buyerCode = item.username
          item.buyerName = item.name
        }
      }
    })
    if (this.$store.state.loginInfo.userType === 1) {
      request('/api/supplier/basicData/list', 'get', { pageNum: 1, pageSize: 999, createUserId: this.$store.state.loginInfo.id }).then((response) => {
        if (response.code === '200') {
          if (response.data.records.length > 0) {
            this.$router.push({ query: { id: response.data.records[0].id } })
            this.initialize()
          }
        }
      })
    }
    this.initialize()
    if (this.$route.query.id) {
      request(`/api/supplier/basicData/getScore/${this.$route.query.id}`, 'post').then(data => {
        console.log(data)
        this.greadtot = data.data
      })
    }
  },
  methods: {
    beforeUpload (file) { 
      if (file.type === 'image/svg+xml') { 
        ElMessage({
          showClose: true,
          message: '不支持上传svg',
          type: 'error'
        })
        return false
      }
      return true
    },
    handleChangeCity (row) {
      if (row.areaData.length) {
        row.province = row.areaData[0]
        row.city = row.areaData[1]
        row.area = row.areaData[2]
      }
    },
    graedte () {
      let tit = 0
      for (const row of this.supplierScoreList) {
        if (!isNaN(parseInt(row.score))) {
          tit = tit + parseInt(row.score)
        }
      }
      return tit
    },
    initialize () {
      var id = this.$route.query.id
      var geturl = `/api/supplier/basicData/getById/${id}`
      var type = this.$route.query.type
      var module = this.$route.params.viewName
      var frameName = this.$route.params.frameName

      const controlLevel = this.$route.query.controlLevel // 控制升降级
      const payClauseChange = this.$route.query.action // 控制付款条件
      const level = this.$route.query.level // 当前等级
      if (module !== 'SupplierRegistration') {
        return false
      }
      if (!id) {
        id = this.$route.query.uuid
        geturl = `/api/supplier/basicData/getByUuid/${id}`
      }
      FlowCtlData.docid = id
      if (id) {
        request(geturl, 'get').then((response) => {
          if (response.code === '200') {
            this.formData = response.data
            if (this.formData.supplierAddressList.length <= 0) {
              this.formData.supplierAddressList = deepClone(SupplierRegistrFormData).supplierAddressList
            }
            if (this.formData.supplierAddressList.length) {
              this.formData.supplierAddressList.map(s => {
                s.areaData = []
                s.areaData.push(s.province)
                s.areaData.push(s.city)
                s.areaData.push(s.area)
              })
            }
            if (this.formData.supplierContactList.length <= 0) {
              this.formData.supplierContactList = deepClone(SupplierRegistrFormData).supplierContactList
            }
            if (!this.formData.fileVoList || this.formData.fileVoList.length <= 0) {
              this.formData.fileVoList = deepClone(SupplierRegistrFormData).fileVoList
            }
            this.formName = response.data.name
            FlowCtlData.docid = id
            if (FlowCtlData.flowAppDetail.length > 3 || (this.formData.erpCode && this.formData.erpCode !== '')) {
            // 查看的时候在最后节点flowEnd可以直接查看打分情况
              if ((FlowCtlData.nodeThisData && FlowCtlData.nodeThisData.length > 0) && (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' || FlowCtlData.nodeThisData[0].NODEID === 'flowStart')) {
                request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}&type=1`, 'get').then((response) => {
                  console.log(response)
                  this.supplierScoreList = response.data
                  console.log(this.supplierScoreList)
                })
              }
            }
          }
          try {
            getApprPsn('540776582185422848').then((val) => { this.formData.cgysp = val })
            getApprPsn('561052060826472448').then((val) => { this.bjfzrsplist = val.split(';') })
            this.formData.cpyzlbhq = ''
            getApprPsn('554180635259949056').then((val) => { this.formData.cpyzlbhq = this.formData.cpyzlbhq + val })
            getApprPsn('542244799739269120').then((val) => { this.formData.cpyzlbhq = this.formData.cpyzlbhq + val })
            getApprPsn('551625315191681024').then((val) => { this.formData.cgbjlsp = val })
          } catch (e) {
            console.log(e)
          }
        })
      } else {
        getApprPsn('540776582185422848').then((val) => { this.formData.cgysp = val })
        getApprPsn('561052060826472448').then((val) => { this.bjfzrsplist = val.split(';') })
        this.formData.cpyzlbhq = ''
        getApprPsn('554180635259949056').then((val) => { this.formData.cpyzlbhq = this.formData.cpyzlbhq + val })
        getApprPsn('542244799739269120').then((val) => { this.formData.cpyzlbhq = this.formData.cpyzlbhq + val })
        getApprPsn('551625315191681024').then((val) => { this.formData.cgbjlsp = val })
      }
      if (type === 'show') {
        this.FormAttr = SupplierRegistrFormShow
        this.formName = this.formData.name
        this.FormAttr.type.readonly = true
        if (controlLevel === 'up') {
          this.canUp = true
          this.canDown = false
          this.canChange = false
          this.canPayClauseChange = false
          this.FormAttr.upgradeLevel.hidden = false
          this.FormAttr.srmFileList.srmFileList.readonly = false
          this.FormAttr.srmFileList.name.readonly = false
          this.FormAttr.resourceFileList.readonly = false
          this.upgradeLevelList = this.upgradeLevelList.filter(item => item.value > level)
        } else if (controlLevel === 'down') {
          this.canUp = false
          this.canDown = true
          this.canChange = false
          this.canPayClauseChange = false
          this.FormAttr.upgradeLevel.hidden = false
          this.FormAttr.srmFileList.name.readonly = false
          this.FormAttr.eliminateOpinion.hidden = false
          this.upgradeLevelList = this.upgradeLevelList.filter(item => item.value < level)
        } else if (payClauseChange === 'payClauseChange') {
          this.canUp = false
          this.canDown = false
          this.canChange = false
          this.canPayClauseChange = true
          this.FormAttr.payClauseCode.readonly = false
          this.FormAttr.payClauseCode.hidden = false
          this.FormAttr.payClauseCode.required = true
        } else {
          this.canUp = false
          this.canDown = false
          this.canPayClauseChange = false
          this.canChange = true
        }
        this.canSave = false
        this.canSubmit = false
        this.canReject = false
        FlowCtlData.initialize().then(() => {
          if (this.$store.state.loginInfo.userType === 1) {
            this.FormAttr.businessScope.hidden = true
            this.FormAttr.type.hidden = true
            this.FormAttr.vmi.hidden = true
            this.FormAttr.status.hidden = true
            this.FormAttr.buyerName.hidden = true
            this.FormAttr.statement.hidden = true
            if (type === 'edit' && frameName === 'myCompany') {
              this.FormAttr.buyerName.hidden = false
            }
          } else {
            if (this.formData.buyerCode === '') {
              this.formData.buyerCode = this.$store.state.loginInfo.username
              this.formData.buyerName = this.$store.state.loginInfo.name
              // this.formData.buyerDtoList = [{ buyerCode: this.$store.state.loginInfo.username, buyerName: this.$store.state.loginInfo.name }]
              this.formData.buyerDtoList = []
            }
          }
          let isAdmin = false
          for (const item of this.$store.state.loginInfo.sysRoles) {
            if (item.id === '571759346363674624') {
              isAdmin = true
            }
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' && this.$store.state.loginInfo.userType === 0 && isAdmin && (!this.formData.erpCode || this.formData.erpCode === '')) {
            this.showCallSap = true
          }
          // 查看的时候在最后节点flowEnd可以直接查看打分情况
          if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' || FlowCtlData.nodeThisData[0].NODEID === 'flowStart') {
            request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}`, 'get').then((response) => {
              console.log(response)
              this.supplierScoreList = response.data
              console.log(this.supplierScoreList)
              // this.FormAttr.resourceFileList.readonly = true
            })
          }
        })
      } else if (type === 'edit' && frameName === 'MainData') {
        this.FormAttr = SupplierRegistrFormEdit
        this.formName = this.formData.name
        this.canChange = false
        this.canSave = false
        this.canSubmit = true
        this.canReject = false
        this.FormAttr.type.readonly = false
        FlowCtlData.initialize().then(() => {
          if (this.$store.state.loginInfo.userType === 1) {
            this.FormAttr.businessScope.hidden = true
            this.FormAttr.type.hidden = true
            this.FormAttr.vmi.hidden = true
            this.FormAttr.status.hidden = true
            this.FormAttr.buyerName.hidden = true
            this.FormAttr.statement.hidden = true
            if (type === 'edit' && frameName === 'myCompany') {
              this.FormAttr.buyerName.hidden = false
            }
          } else {
            if (this.formData.buyerCode === '') {
              this.formData.buyerCode = this.$store.state.loginInfo.username
              this.formData.buyerName = this.$store.state.loginInfo.name
              // this.formData.buyerDtoList = [{ buyerCode: this.$store.state.loginInfo.username, buyerName: this.$store.state.loginInfo.name }]
              this.formData.buyerDtoList = []
            }
          }
          let isAdmin = false
          for (const item of this.$store.state.loginInfo.sysRoles) {
            if (item.id === '571759346363674624') {
              isAdmin = true
            }
          }
          // 当在 产品与质量部会签 环节时调用打分详情接口
          if (FlowCtlData.nodeThisData[0].NODEID === 'e4wX3pyhGCDQCBctnXnhxpn4342Q2aFR') {
            request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}`, 'get').then((response) => {
              this.supplierScoreList = response.data
            })
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' && this.$store.state.loginInfo.userType === 0 && isAdmin && (!this.formData.erpCode || this.formData.erpCode === '')) {
            this.showCallSap = true
          }
          // 当此节点时进入打分页面
          if (FlowCtlData.nodeThisData[0].NODEID === 'e4wX3pyhGCDQCBctnXnhxpn4342Q2aFR') {
            this.canSubmit = false
            this.canReject = false
            this.canGrade = true
          }
        })
      // eslint-disable-next-line no-constant-condition
      } else if (type === 'edit' && frameName === 'myCompany' && false) {
        this.FormAttr = SupplierRegistrFormAttrCompany
        this.formName = this.formData.name
        this.FormAttr.srmFileList.readonly = true
        this.canChange = false
        this.canSave = false
        this.canSubmit = true
        this.canReject = false
      } else {
        this.FormAttr = SupplierRegistrFormAttr
        this.formName = '新建供应商'
        this.canChange = false
        this.canSave = true
        this.canSubmit = true
        this.canReject = false
        FlowCtlData.initialize().then(() => {
          if (!FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
            this.FormAttr = SupplierRegistrFormShow
            this.canChange = false
            this.canSave = false
            this.canSubmit = false
            this.canReject = false
          } else if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) && FlowCtlData.nodeThisData[0].NODEID === 'knmcScbbARwykjKknNr5Xm767sy7rTfx') {
            this.FormAttr = SupplierRegistrFormEdit
            this.canChange = false
            this.canSave = true
            this.canSubmit = true
            this.havebjfzrsp = true
            this.initbjfzrsphw = true
            this.canReject = true
          } else if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) && FlowCtlData.nodeThisData[0].NODEID === 'flowStart') {
            this.FormAttr = SupplierRegistrFormAttr
            this.canChange = false
            this.canSave = true
            this.canSubmit = true
            this.canReject = false
          } else {
            this.FormAttr = SupplierRegistrFormShow
            this.canChange = false
            this.canSave = false
            this.canSubmit = true
            this.canReject = true
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'e4wX3pyhGCDQCBctnXnhxpn4342Q2aFR' || FlowCtlData.nodeThisData[0].NODEID === 'jQjX4zECX2f2ArHbzCQY4eAEcj3YSFH5') {
            request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}`, 'get').then((response) => {
              this.supplierScoreList = response.data
            })
          }
          console.log(this.formData)
          if (this.formData.erpCode && this.formData.erpCode !== '') {
            // 查看的时候在最后节点flowEnd可以直接查看打分情况
            if ((FlowCtlData.nodeThisData && FlowCtlData.nodeThisData.length > 0) && (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' || FlowCtlData.nodeThisData[0].NODEID === 'flowStart')) {
              request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}`, 'get').then((response) => {
                console.log(response)
                this.supplierScoreList = response.data
                console.log(this.supplierScoreList)
              })
            }
          }
          if (this.$store.state.loginInfo.userType === 1) {
            this.FormAttr.businessScope.hidden = true
            this.FormAttr.type.hidden = true
            this.FormAttr.vmi.hidden = true
            this.FormAttr.status.hidden = true
            this.FormAttr.buyerName.hidden = true
            this.FormAttr.statement.hidden = true
            if (type === 'edit' && frameName === 'myCompany') {
              this.FormAttr.buyerName.hidden = false
            }
          } else {
            if (this.formData.buyerCode === '') {
              this.formData.buyerCode = this.$store.state.loginInfo.username
              this.formData.buyerName = this.$store.state.loginInfo.name
              // this.formData.buyerDtoList = [{ buyerCode: this.$store.state.loginInfo.username, buyerName: this.$store.state.loginInfo.name }]
              this.formData.buyerDtoList = []
            }
            if (FlowCtlData.nodeThisData[0].NODEID === 'flowStart') {
              this.initbjfzrsphw = true
              this.havebjfzrsp = true
            }
          }
          this.FormAttr.status.hidden = true
          let isAdmin = false
          if (this.$store.state.loginInfo.sysRoles) {
            for (const item of this.$store.state.loginInfo.sysRoles) {
              if (item && item.id === '571759346363674624') {
                isAdmin = true
              }
            }
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' && this.$store.state.loginInfo.userType === 0 && isAdmin && (!this.formData.erpCode || this.formData.erpCode === '')) {
            this.showCallSap = true
          }
          // 当此节点时进入打分页面
          if (FlowCtlData.nodeThisData[0].NODEID === 'e4wX3pyhGCDQCBctnXnhxpn4342Q2aFR') {
            this.canSubmit = false
            this.canReject = false
            this.canGrade = true
          }
          if (FlowCtlData.nodeThisData[0].NODEID === 'e4wX3pyhGCDQCBctnXnhxpn4342Q2aFR') {
            request(`/api/supplier/basicData/getScoreMes?id=${this.$route.query.id}`, 'get').then((response) => {
              this.supplierScoreList = response.data
            })
          }
        })
      }
    },
    verifyEmail (Code, testData, testDataCode) {
      if (Code.trim() === '') {
        return false
      }
      if (Code.indexOf('.') < 0) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '邮箱不正确！',
          type: 'error'
        })
        return false
      }
      const reg = /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/
      if (!reg.test(Code)) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '邮箱不正确！',
          type: 'error'
        })
      }
    },
    verifyPhone (Code, testData, testDataCode) {
      if (Code.trim() === '') {
        return false
      }
      // const reg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      const reg = /[^\d]/
      if (reg.test(Code)) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '电话号不正确！',
          type: 'error'
        })
      }
    },
    verifyBankCard (Code, testData, testDataCode) {
      if (Code.trim() === '') {
        return false
      }
      /* const reg = /^([0-9]{1})(\d{8,30})$/
      if (!reg.test(Code)) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '银行卡号不正确！',
          type: 'error'
        })
      } */
    },
    verifySocialCode (Code, testData, testDataCode) {
      if (Code.trim() === '') {
        return false
      }
      /* const reg = /^[a-zA-Z0-9]{4,100}$/
      if (!reg.test(Code)) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '信用代码不正确！',
          type: 'error'
        })
      } */
      /*       if ((Code.length !== 18)) {
        testData[testDataCode] = ''
        ElMessage({
          showClose: true,
          message: '不是有效的统一社会信用编码！',
          type: 'error'
        })
        return false
      } else {
        var Ancode
        var Ancodevalue
        var total = 0
        var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28]
        var str = '0123456789ABCDEFGHJKLMNPQRTUWXY'
        for (var i = 0; i < Code.length - 1; i++) {
          Ancode = Code.substring(i, i + 1)
          Ancodevalue = str.indexOf(Ancode)
          total = total + Ancodevalue * weightedfactors[i]
        }
        var logiccheckcode = 31 - total % 31
        if (logiccheckcode === 31) {
          logiccheckcode = 0
        }
        var Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y'
        var ArrayStr = Str.split(',')
        logiccheckcode = ArrayStr[logiccheckcode]
        var checkcode = Code.substring(17, 18)
        if (logiccheckcode !== checkcode) {
          testData[testDataCode] = ''
          ElMessage({
            showClose: true,
            message: '不是有效的统一社会信用编码！',
            type: 'error'
          })
        } else {
          console.info('yes')
        }
      } */
    },
    clickKey (key) {
      this.uploadKey = key
    },
    callSap () {
      const supid = this.formData
      request(`/api/supplier/basicData/callSapSaveSupplier?supplierId=${supid.id}&userId=${supid.createUserId}`, 'get').then((response) => {
        if (response.code === '200') {
          this.$message({
            type: 'success',
            message: '调用成功!'
          })
        }
      })
    },
    back: () => {
      window.history.back()
    },
    addlist (list, addData) {
      var data = JSON.parse(JSON.stringify(addData))
      list.push(data)
    },
    dellist (list, key) {
      var type = this.$route.query.type
      if (type === 'edit' || (this.erpCode && this.erpCode !== '')) {
        if (list[key].name === '营业执照') {
          this.$message({
            type: 'error',
            message: '不允许删除营业执照！'
          })
        } else {
          if (list[key].name === '品质认证文件' || list[key].name === '产品技术文件') {
            // this.mustApproval = true
          }
          if (list.length > 1) {
            list.splice(key, 1)
          }
        }
      } else {
        if (list.length > 1) {
          list.splice(key, 1)
        }
      }
    },
    changAction (data) {
      var index = data.addrIndex
      if (data.country) {
        this.formData.supplierAddressList[index].country = data.country
      }
      if (data.province) {
        this.formData.supplierAddressList[index].province = data.province
      }
      if (data.city) {
        this.formData.supplierAddressList[index].city = data.city
      }
      if (data.district) {
        this.formData.supplierAddressList[index].area = data.district
      }
    },
    async saveData () {
      var df = false
      await request('/api/supplier/basicData/save', 'post', this.formData).then(
        (response) => {
          if (response.msg === 'success') {
            FlowCtlData.docid = response.data
            df = response.data
          } else {
            this.$message({
              type: 'error',
              message: response.msg
            })
          }
        })
      return df
    },
    async saveDataNew (subtype) {
      var df = false
      let url = '/api/supplier/basicData/updateApprovalSupplier'
      if (subtype) {
        url = url + '?type=1'
      }
      await request(url, 'post', this.formData).then(
        (response) => {
          if (response.msg === 'success') {
            FlowCtlData.docid = response.data
            df = response.data
          } else {
            this.$message({
              type: 'error',
              message: response.msg
            })
          }
        })
      return df
    },
    save () {
      this.$confirm('是否保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (FlowCtlData.nodeThisData[0].NODEID === 'flowStart') {
          if (!this.formData.erpCode || this.formData.erpCode === '') {
            this.formData.approvalStatus = 1
          }
        }
        this.saveData().then((val) => {
          if (val) {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            this.$router.push({ query: { id: val } })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消保存'
        })
      })
    },
    async submit () {
      console.log('this.formData', this.formData)
      let wj = false
      for (const item of this.formData.fileVoList) {
        if (item.name === '营业执照' && item.srmFileList.length > 0) {
          wj = true
        }
      }
      if (!wj) {
        ElMessage({
          showClose: true,
          message: '请上传营业执照！',
          type: 'error'
        })
        return false
      }
      var _this = this
      var requireds = document.querySelectorAll('[required]')
      var formroler = true
      for (var i = 0; i < requireds.length; i++) {
        var thisdom = requireds[i]
        if (thisdom.nodeName.toLowerCase() === 'input') {
          if (thisdom.getAttribute('type') === 'radio' || thisdom.getAttribute('type') === 'checkbox') {
            var domName = thisdom.name
            var reackdom = document.getElementsByName(domName)
            var isPass = false
            for (var d = 0; d < reackdom.length; d++) {
              if (reackdom[d].checked) {
                isPass = true
              }
            }
            if (!isPass) {
              thisdom.parentNode.parentNode.style.backgroundColor = '#fcffc0'
              formroler = false
            }
          } else {
            if (thisdom.value.trim() === '') {
              thisdom.style.backgroundColor = '#fcffc0'
              formroler = false
            }
          }
        } else {
          if (thisdom.getAttribute('class') === 'el-select' && thisdom.querySelector('.el-select__tags') !== null) {
            if (thisdom.querySelectorAll('.el-tag').length <= 0) {
              thisdom.querySelector('.el-input__inner').style.backgroundColor = '#fcffc0'
              formroler = false
            }
          } else if (thisdom.querySelector('.el-input__inner') !== null && thisdom.querySelector('.el-input__inner').value.trim() === '') {
            thisdom.querySelector('.el-input__inner').style.backgroundColor = '#fcffc0'
            formroler = false
          }
        }
      }
      if (!formroler) {
        ElMessage({
          showClose: true,
          message: '请将表单填写完整！',
          type: 'error'
        })
        return false
      }
      this.formData.buyerCode = ''
      for (const item of this.formData.buyerDtoList) {
        this.formData.buyerCode = this.formData.buyerCode + item.buyerCode + ';'
      }
      this.formData.cpyzlbhq = this.formData.cpyzlbhq + ';' + this.formData.buyerCode
      if (this.formData.erpCode && this.formData.erpCode !== '' && (FlowCtlData.nodeThisData[0].NODEID === 'flowStart' || FlowCtlData.nodeThisData[0].NODEID === 'flowEnd')) {
        if (!this.mustApproval) {
          this.formData.approvalStatus = 3
          if ((this.formData.upgradeLevel ?? '') !== '') {
            if (this.formData.upgradeLevel === '0') {
              this.formData.status = 1
            } else {
              this.formData.type = this.formData.upgradeLevel
            }
            this.formData.upgradeLevel = null
          }
          this.saveData().then(() => {
            request(`/api/supplier/basicData/callSapSaveSupplier?supplierId=${this.formData.id}&userId=${this.formData.createUserId}`, 'get').then(() => {
              this.$message({
                type: 'success',
                message: '提交成功!'
              })
            })
            window.history.back()
          })
        } else {
          FlowCtlData.docid = ''
          FlowCtlData.getNodeAction('TJ', this.formData).then((val) => {
            if (val) {
              if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                this.formData.approvalStatus = 3
              } else if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowStart') {
                this.formData.approvalStatus = 1
              } else {
                this.formData.approvalStatus = 2
              }
              if (this.formData.approvalComplete === null || this.formData.approvalComplete === undefined) {
                this.formData.approvalComplete = ''
              }
              if (this.formData.approval === null || this.formData.approval === undefined) {
                this.formData.approval = ''
              }
              this.formData.approvalComplete = this.formData.approvalComplete + this.formData.approval + ','
              this.formData.approval = FlowCtlData.tmpNextMsgInfo.psnMsg.psnId
              if (!this.formData.approval) {
                this.formData.approval = ''
              }
              this.$prompt('下一环节：' + FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeName + '。是否提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请填写审批意见',
                type: 'warning'
              }).then(({ value }) => {
                FlowCtlData.setFormData(FlowCtlData, this.formData)
                var url = '/api/supplier/basicData/updateApprovalSupplier?type=1'
                request(url, 'post', this.formData).then((response) => {
                  if (response.msg === 'success') {
                    FlowCtlData.docid = response.data
                    FlowCtlData.setNodeAction(value).then((val) => {
                      if (val) {
                        FlowCtlData.formAlert(this, 'success')
                        if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                          request(`/api/supplier/basicData/callSapSaveSupplier?supplierId=${this.formData.id}&userId=${this.formData.createUserId}`, 'get')
                        }
                        if (this.$store.state.loginInfo.userType === 1) {
                          this.$router.push({ query: { id: response.data } })
                        } else {
                          window.history.back()
                        }
                      }
                    }).catch(() => { FlowCtlData.formAlert(this, 'error') })
                  } else {
                    this.$message({
                      type: 'error',
                      message: response.msg
                    })
                  }
                })
              }).catch(() => { FlowCtlData.formAlert(this, 'info') })
            }
          })
        }
        return false
      }
      this.formData.step = this.formData.type // 新建加step值
      var tf = FlowCtlData.getNodeAction('TJ', this.formData)
      if (FlowCtlData.nodeThisData[0].NODEID === 'flowStart' && FlowCtlData.flowAppDetail.length <= 1) {
        // const s = 0
        this.formData.createUserId = localStorage.getItem('userId')
        /* await request(`/api/supplier/basicData/getByCreditCode?code=${this.formData.creditCode}&id=${this.formData.id}`, 'get').then((response) => {
          if (response.code === '200') {
            if (!response.data) {
              ElMessage({
                showClose: true,
                message: '公司已被注册！',
                type: 'error'
              })
              s = 1
            }
          }
        })
        if (s === 1) {
          return false
        } */
      }
      tf.then((val) => {
        if (val) {
          _this.$prompt('下一环节：' + FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeName + '。是否提交？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '请填写审批意见',
            type: 'warning'
          }).then(({ value }) => {
            if ((this.formData.buyerCode === '' || this.formData.buyerName === '') && FlowCtlData.nodeThisData[0].NODEID === 'knmcScbbARwykjKknNr5Xm767sy7rTfx') {
              this.formData.buyerCode = this.$store.state.loginInfo.username
              this.formData.buyerName = this.$store.state.loginInfo.name
              // this.formData.buyerDtoList = [{ buyerCode: this.$store.state.loginInfo.username, buyerName: this.$store.state.loginInfo.name }]
              this.formData.buyerDtoList = []
            }
            if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
              this.formData.approvalStatus = 3
            } else if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowStart') {
              this.formData.approvalStatus = 1
            } else {
              this.formData.approvalStatus = 2
            }
            if (this.formData.approvalComplete === null || this.formData.approvalComplete === undefined) {
              this.formData.approvalComplete = ''
            }
            if (this.formData.approval === null || this.formData.approval === undefined) {
              this.formData.approval = ''
            }
            this.formData.approvalComplete = this.formData.approvalComplete + this.formData.approval + ','
            this.formData.approval = FlowCtlData.tmpNextMsgInfo.psnMsg.psnId
            if (!this.formData.approval) {
              this.formData.approval = ''
            }
            if (this.formData.erpCode && this.formData.erpCode !== '') {
              var bcdata
              if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                if (this.formData.upgradeLevel === '0') {
                  this.formData.status = 1
                } else {
                  this.formData.type = this.formData.upgradeLevel
                }
                this.formData.upgradeLevel = ''
                bcdata = this.saveData()
              } else {
                bcdata = this.saveDataNew()
              }
              bcdata.then((id) => {
                if (id) {
                  var idd = this.$route.query.id
                  FlowCtlData.docid = idd
                  FlowCtlData.setNodeAction(value).then((val) => {
                    if (val) {
                      _this.$message({
                        type: 'success',
                        message: '提交成功!'
                      })
                      const supid = this.formData
                      if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                        request(`/api/supplier/basicData/callSapSaveSupplier?supplierId=${supid.id}&userId=${supid.createUserId}`, 'get').then((response) => {
                          if (response.code === '200') {
                          }
                        })
                      }
                      if (this.$store.state.loginInfo.userType === 1) {
                        this.$router.push({ query: { id: id } })
                      } else {
                        window.history.back()
                      }
                      this.FormAttr = SupplierRegistrFormShow
                      this.canChange = false
                      this.canSave = false
                      this.canSubmit = false
                      this.canReject = false
                    }
                  }).catch(() => {
                    _this.$message({
                      type: 'error',
                      message: '系统异常'
                    })
                  })
                }
              })
            } else {
              this.saveData().then((id) => {
                if (id) {
                  FlowCtlData.setNodeAction(value).then((val) => {
                    if (val) {
                      _this.$message({
                        type: 'success',
                        message: '提交成功!'
                      })
                      const supid = this.formData
                      let suptid = supid.id
                      if ((suptid ?? '').trim() === '') {
                        suptid = id
                      }
                      if (FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId === 'flowEnd') {
                        request(`/api/supplier/basicData/callSapSaveSupplier?supplierId=${suptid}&userId=${supid.createUserId}`, 'get').then((response) => {
                          if (response.code === '200') {
                          }
                        })
                      }
                      if (this.$store.state.loginInfo.userType === 1) {
                        this.$router.push({ query: { id: id } })
                      } else {
                        window.history.back()
                      }
                      this.FormAttr = SupplierRegistrFormShow
                      this.canChange = false
                      this.canSave = false
                      this.canSubmit = false
                      this.canReject = false
                    }
                  }).catch(() => {
                    _this.$message({
                      type: 'error',
                      message: '系统异常'
                    })
                  })
                }
              })
            }
          }).catch(() => {
            _this.$message({
              type: 'info',
              message: '已取消提交'
            })
          })
        }
      })
    },
    reject () {
      this.formData.buyerCode = ''
      for (const item of this.formData.buyerDtoList) {
        this.formData.buyerCode = this.formData.buyerCode + item.buyerCode + ';'
      }
      /*       if (this.formData.erpCode && this.formData.erpCode !== '') {
        FlowCtlData.getNodeAction('BH', this.formData).then(
          (val) => {
            if (val) {
              FlowCtlData.alertBHDialog(this).then(({ value }) => {
                FlowCtlData.setFormData(FlowCtlData, this.formData)
                request('', 'post', this.formData).then((res) => {
                  if (res.code === '200') {
                    FlowCtlData.setNodeAction(value).then((val) => {
                      if (val) {
                        this.$message({
                          message: '驳回成功',
                          type: 'success'
                        })
                      } else {
                        this.$message({
                          message: '操作失败',
                          type: 'error'
                        })
                      }
                    }).catch(() => { FlowCtlData.formAlert(this, 'error') })
                  }
                })
              })
            }
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        return false
      } */
      var tf = FlowCtlData.getNodeAction('BH', this.formData)
      var _this = this
      tf.then((val) => {
        if (val) {
          _this.$prompt('下一环节：开始。是否驳回？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '请填写审批意见',
            type: 'warning'
          }).then(({ value }) => {
            this.formData.approvalStatus = 1
            FlowCtlData.setFormData(FlowCtlData, this.formData)
            this.saveData().then((id) => {
              if (id) {
                FlowCtlData.setNodeAction(value).then((val) => {
                  if (val) {
                    _this.$message({
                      type: 'success',
                      message: '驳回成功!'
                    })
                    window.history.back()
                  }
                })
              }
            })
          }).catch((error) => {
            console.log(error)
            _this.$message({
              type: 'info',
              message: '已取消驳回'
            })
          })
        }
      })
    },
    change () {
      this.$router.push({ query: { id: this.$route.query.id, type: 'edit' } })
    },
    upGrade () {
      if (this.formData.upgradeLevel === '2') {
        this.mustApproval = false
        this.submit()
      } else {
        this.mustApproval = true
        this.formData.step = '4'
        if (this.formData.resourceFileList.length <= 0) {
          this.$message({
            message: '请上传资料调查表',
            type: 'error'
          })
          return false
        }
        this.submit()
      }
    },
    // 降级全要走提交审批
    downGrade () {
      this.mustApproval = true
      this.formData.step = '2'
      this.submit()
    },
    // 付款条件全要走提交审批
    payClauseChangeACt () {
      this.mustApproval = true
      this.formData.step = '2'
      this.submit()
    },
    // 打分换成别的提交按钮
    grade () {
      request(`/api/supplier/basicData/saveScore/${this.$route.query.id}`, 'post', this.supplierScoreList).then(() => {
        const tf = FlowCtlData.getNodeAction('TJ', this.formData)
        tf.then((val) => {
          if (val) {
            if (FlowCtlData.nodeThisData[0].NODEID === FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeId) {
              this.submit()
            } else {
              // 如果流程节点不一致则说明应该进行总分判断
              request(`/api/supplier/basicData/getScore/${this.$route.query.id}`, 'post').then(data => {
                if (data && data.data >= 60) {
                  this.submit()
                } else {
                  // this.submit()
                  this.formData.buyerCode = ''
                  for (const item of this.formData.buyerDtoList) {
                    this.formData.buyerCode = this.formData.buyerCode + item.buyerCode + ';'
                  }
                  FlowCtlData.getNodeAction('BH', this.formData).then((val) => {
                    if (val) {
                      this.$confirm('下一环节：开始。是否提交？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        this.formData.approvalStatus = 1
                        FlowCtlData.setFormData(FlowCtlData, this.formData)
                        let url = '/api/supplier/basicData/save'
                        if (this.formData.erpCode && this.formData.erpCode !== '') {
                          url = '/api/supplier/basicData/updateApprovalSupplier'
                        }
                        request(url, 'post', this.formData).then(
                          (response) => {
                            if (response.msg === 'success') {
                              FlowCtlData.setNodeAction('总分未及格或被一票否决！').then((val) => {
                                if (val) {
                                  this.$message({
                                    type: 'success',
                                    message: '提交成功!'
                                  })
                                  window.history.back()
                                }
                              })
                            } else {
                              this.$message({
                                type: 'error',
                                message: response.msg
                              })
                            }
                          })
                      }).catch((error) => {
                        console.log(error)
                        this.$message({
                          type: 'info',
                          message: '已取消'
                        })
                      })
                    }
                  })
                }
              })
            }
          }
        })
      })
    },
    handleRemove (file) {
      for (var i = 0; i < this.formData.fileVoList[this.uploadKey].srmFileList.length; i++) {
        if (this.formData.fileVoList[this.uploadKey].srmFileList[i].id === file.id) {
          this.formData.fileVoList[this.uploadKey].srmFileList.splice(i, 1)
        }
      }
    },
    handleRemoveResource (file) { 
      for (var i = 0; i < this.formData.resourceFileList.length; i++) {
        if (this.formData.resourceFileList[i].id === file.id) {
          this.formData.resourceFileList.splice(i, 1)
        }
      }
    },
    handlePreview (file) {
      exportForm(`/api/file/m/file/downloadFile/${file.id}`, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      if (file && file.status == 'success') {
        return this.$confirm(`确定移除 ${file.name}？`)
      }
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        let postData = {
          name: file.name,
          url: response.data
        }
        request('/api/file/m/file/saveParstorFile', 'post', postData).then((res) => {
          if (res.code === '200') {
            this.formData.fileVoList[this.uploadKey].srmFileList.push(res.data)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
        })
        
      } else {
        this.$notify.error({
          title: '错误',
          message: '文件上传失败'
        })
      }
    },
    handleSuccessResource (response, file, fileList) {
      if (response.code === '200') {
        let postData = {
          name: file.name,
          url: response.data
        }
        request('/api/file/m/file/saveParstorFile', 'post', postData).then((res) => {
          if (res.code === '200') {
            this.formData.resourceFileList.push(res.data)
          } else {
            this.$notify.error({
              title: '错误',
              message: res.msg
            })
          }
        })
        
      } else {
        this.$notify.error({
          title: '错误',
          message: '文件上传失败'
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    checkNum (e, tallestScore, index) {
      // if (e.target.value !== '') {
      const re = /^[+]{0,1}(\d+)$/
      if (!re.test(e.target.value)) {
        this.supplierScoreList[index].score = 0
      } else if (parseInt(e.target.value) >= parseInt(tallestScore)) {
        this.supplierScoreList[index].score = parseInt(tallestScore)
      } else if (parseInt(e.target.value) <= 0) {
        this.supplierScoreList[index].score = 0
      } else {
        this.supplierScoreList[index].score = parseInt(e.target.value)
      }
      // }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  .muban{
    color: #7d7d7d;
    display: flex;
    width: 480px;
    cursor: pointer;
    margin-top: 10px;
    justify-content: space-between;
  }
</style>
